@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Myanmar:wght@100;200;300;400;500;600&display=swap');

*{
    font-size: 14px;
}

body {
    background: #222222;
}

::-webkit-scrollbar {
    width: 0px;
}

body {
    padding: 0px;
    margin: 0px;
    font-family: 'sans-serif';
}

#root {
    min-height: 100vh !important;
}

.p-image.not-found-logo>img {
    border-radius: 20px;
}

.not-found-circle-one {
    position: absolute;
    top: -100px;
    right: -100px;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #EA2B4A;
}

.not-found-circle-two {
    position: absolute;
    bottom: -100px;
    left: -100px;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: #EA2B4A;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

.primary-color {
    background: #222222;
    color: #fff;
}

.input-label > span {
    color: #ff0000;
}

.text-gray {
    color: #6c757d;
}

.promotion-image {
    width: 100% !important;
    height: 300px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.promotion-image .img-promo {
    width: 100%;
    height: 100%;
    border-radius: 25px;
}

.text-2xl {
    font-size: 2rem;
}

@media (max-width: 720px) {
    .promotion-image {
        width: 400px;
        height: 200px;
    }
}

@media (max-width: 480px) {
    .promotion-image {
        width: 300px;
        height: 200px;
    }
}

.h-full {
    height: 100% !important;
}

.w-300 {
    width: 300px !important;
}

.w-200 {
    width: 200px !important;
}

.h-300 {
    height: 300px !important;
}

.h-40 {
    height: 40px !important;
}

.h-60 {
    height: 60px !important;
}

.h-126 {
    height: 126px !important;
}

.h-200 {
    height: 200px !important;
}

.bg-gray-300 {
    background-color: #e5e7eb;
}

.input-label {
    color: #fff;
    font-weight: 600;
}

.title {
    font-weight: 600;
}

.sidebar-header>h3,
.sidebar-header>small {
    padding: 0px;
    margin: 0px;
}

.bg-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #10101121;
    border-radius: 6px;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-loading>img {
    width: 100px;
    height: 100px;
}

.search {
    position: relative;
}

.remove-search {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #ced4da;
    position: absolute;
    top: 50%;
    right: 55px;
    transform: translateY(-50%);
    z-index: 9;
}

.remove-search-icon {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.remove-search>span>i {
    font-size: 10px;
    color: #6c757d;
}

.count-card {
    width: 100%;
    height: 166px;
    color: #000;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    overflow: hidden;
}

.count-status {
    color: #000;
    padding: 3px 6px;
    border-radius: 10px;
    font-weight: bold;
}

.count-view {
    margin-top: auto;
}

.count-view.total {
    color: #fff;
    background-color: #EA2B4A;
}

.count-view.verified {
    color: #fff;
    background-color: #17b978;
}

.count-view.pending {
    color: #fff;
    background-color: darkorange;
}

.count-view.delivery {
    color: #fff;
    background-color: #4338CA;
}

.count-view.complete {
    color: #fff;
    background-color: #21a321;
}

.count-view.disable {
    color: #fff;
    background-color: #6c757d;
}

.count-disable {
    color: #fff;
    background-color: #6c757d;
}

.count-complete {
    color: #fff;
    background-color: #21a321;
}

.count-total {
    color: #fff;
    background: #EA2B4A;
}

.count-verified {
    color: #fff;
    background-color: #17b978;
}

.count-pending {
    color: #fff;
    background-color: darkorange;
}

.count-delivery {
    color: #fff;
    background-color: #4338CA;
}

.count-card.status.order {
    color: #EA2B4A;
    background-color: #fff;
}

.count-card.status.item {
    color: #482ff7;
    background-color: #fff;
}

.count-card.status.user {
    color: #17b978;
    background-color: #fff;
}

.count-card.order {
    background-color: #EA2B4A;
}

.count-card.item {
    background-color: #482ff7;
}

.count-card.user {
    background-color: #17b978;
}

.count-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.count-icon.order {
    color: #EA2B4A;
}

.count-icon.status.order {
    color: #fff;
    background-color: #EA2B4A;
}

.count-icon.status.item {
    color: #fff;
    background-color: #482ff7;
}

.count-icon.status.user {
    color: #fff;
    background-color: #17b978;
}

.count-icon.item {
    color: #482ff7;
}

.count-icon.user {
    color: #17b978;
}

.custom-profile {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #dee2e6;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.custom-profile::after {
    content: '';
    width: 120px;
    height: 120px;
    position: absolute;
    left: 50%;
    top: 50%;
    border: 1px dashed #dee2e6;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.custom-profile>span {
    font-size: xx-large;
    font-weight: 500;
}

.member-card-null {
    width: 100%;
    height: 200px;
    background-color: #ced4da;
    border-radius: 20px;
}

/** Layout **/
.wrapper {
    width: 100%;
    position: absolute;
}

.app-toolbar {
    width: 100%;
    position: fixed;
    z-index: 99;
}

.app-container {
    width: 100%;
    color: #fff;
    margin-top: 63px;
}

.app-content {
    width: 100%;
    background: #1c1b1b;
    color: #fff;
}

.nav-link {
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
}

.nav-link:hover {
    text-decoration: underline;
}

/** Button **/
.menu-btn {
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
}

.error-message {
    color: #ec2929;
    font-size: small;
    font-weight: 400;
}

/** Overwrite CSS **/
.grid {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
}

.p-card {
    background: #222222;
    color: #fff;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    position: relative;
}

.toolbar {
    width: 100%;
    border-radius: 0px;
    min-height: 50px;
    padding: 0px;
    border: 0px;
}

.p-sidebar {
    box-shadow: 0px 2px 3px 0px rgb(41 38 38);
    -webkit-box-shadow: 0px 2px 3px 0px rgb(41 38 38);
    -moz-box-shadow: 0px 2px 3px 0px rgb(41 38 38);
}

.p-sidebar-header {
    padding: 10px;
    font-weight: 500;
    background-color: #1c1b1b;
    color: #fff;
    box-shadow: 0px 2px 3px 0px rgb(41 38 38) !important;
    -webkit-box-shadow: 0px 2px 3px 0px rgb(41 38 38) !important;
    -moz-box-shadow: 0px 2px 3px 0px rgb(41 38 38) !important;
}

.p-sidebar-content {
    padding: 0px;
    padding-top: 10px;
    background-color: #1c1b1b;
    color: #fff;

}

.p-tree {
    border: 0px;
    background-color: #1c1b1b;
    color: #fff;
}

.p-menu-overlay>ul>li>a>span {
    color: #000;
}

.p-inputtext {
    color: #000;
    width: 100%;
}

.p-avatar {
    width: 100px;
    height: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-avatar::after {
    content: '';
    width: 120px;
    height: 120px;
    position: absolute;
    left: 50%;
    top: 50%;
    border: 1px dashed #dee2e6;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

/** --Breadcrumb--  **/
.breadcrumbs-card,
.breadcrumbs-card>.p-card-body,
.breadcrumbs-card>.p-card-body>.p-card-content {
    padding: 0px;
    margin: 0px;
}

.breadcrumbs-card {
    margin-top: 5px;
    color: #fff;
}

.breadcrumbs-card>.p-card-body>.p-card-content {
    padding: 10px;
}

.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.breadcrumb-item {
    padding: 5px;
    cursor: pointer;
}

.breadcrumb-item>label {
    cursor: pointer;
}

.breadcrumb-item>label:hover {
    text-decoration: underline;
    color: blue;
}

/** --End of breadcrumb--  **/

/** Card **/
.p-card-subtitle,
.p-card-title {
    padding: 0 0.5rem;
    font-weight: 600;
    font-size: 20px;
}

.p-card-subtitle {
    font-size: 16px;
    color: #a8a8a8;
}

/** Chip **/
.p-chip .p-chip-text {
    font-size: small;
    font-weight: 500;
}

.chip-success {
    background: #21a321;
    color: #fff;
}

.chip-danger {
    background: #DC2626;
    color: #fff;
}

.chip-info {
    background: #21a392;
    color: #fff;
}

.chip-warn {
    background-color: #eec137;
    color: #fff;
}

.profile {
    padding: 0px;
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.profile>img {
    border-radius: 50%;
    border: 2px solid #fff;
    margin-right: 5px;
    margin-left: 5px;
}

.profile>p {
    padding: 0px;
    margin: 0px;
}

.profile>p:last-child {
    color: #f0f0f0;
}

.category-icon {
    width: 50px;
    height: 50px;
}

.category-icon::after {
    content: "" !important;
    width: 0px;
    height: 0px;
}

.relative {
    position: relative;
}

.thumbnail-title {
    font-size: 20px;
    margin: 0;
    padding-bottom: 20px;
}

.custom-thumbnail {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 22px -11px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(221, 221, 221)
}

.custom-thumbnail span {
    font-size: 50px;
    color: #dee2e6
}

.p-inputgroup-addon {
    height: 40px !important;
}

.p-colorpicker {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.p-colorpicker .p-inputtext {
    width: 50px;
    height: 40px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.p-multiselect {
    height: 40px;
}


/** Components **/
/** Color Picker **/
.multi-color-picker>.p-colorpicker,
.multi-color-picker>.p-inputtext {
    height: 48px !important;
}

.color-circle-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.color-circle {
    width: 50px;
    height: 50px;
    background-color: gold;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.color-circle>span {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.generate-password-addon {
    cursor: pointer;
    color: #000;
    font-weight: 700;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background-color: #6b6b6b;
    color: #fff;
}

.p-datatable-header {
    background-color: #222222;
    color: #fff;
    border: 0px;
}

.p-inputtext {
    background-color: #1c1b1b;
    border:1px solid #5d5d5d;
    color: #fff;
}

.p-inputtext:focus {
    border: 1px solid #5d5d5d;
}

.p-inputgroup-addon, .p-dropdown, .p-dropdown-items, .p-dropdown-item, .p-dropdown-item:hover,
.p-datatable-thead, 
.p-sortable-column,
.p-datatable .p-datatable-tbody > tr,
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable-footer,
.p-button,
.p-button-icon-only, 
.p-button-outlined,
.p-button-sm,
.p-checkbox-box,
.p-highlight,
.p-paginator {
    background-color: #1c1b1b!important;
    border: 1px solid #5d5d5d;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
}

.p-dropdown-item,
.p-sortable-column,
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable-footer,
.p-paginator  {
    border: 0px;
    border-radius: 0px;
}

.p-datatable-wrapper {
    border-bottom: 0px;
}

.pi {
    color: #d3d3d3;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}


.p-editor-container {
    width: 100%;
}

.navbar-brand {
    color: #fff;
}